<template>
  <StMBanners
    :items="formattedTopTournaments"
    :is-mini="isMini"
    :size="props.size"
  >
    <template v-if="isPreBannerVisible" #preBanner>
      <StSliderItem v-if="isLiveVisible">
        <LiveNowLinkBanner :counter="liveCounter" :is-mini="isMini" />
      </StSliderItem>
      <StSliderItem v-if="ratRaceWidget">
        <MTournamentMenuItem :card="ratRaceWidget" :is-mini="isMini" />
      </StSliderItem>
    </template>
  </StMBanners>
</template>

<script setup lang="ts">
import StMBanners from '@st/ui/components/StMBanners/StMBanners.vue'
import MTournamentMenuItem from '@st/tournaments/components/TournamentMenuItem/MTournamentMenuItem.vue'
import { usePageTournament } from '@st/tournaments/composables/usePageTournament'
import LiveNowLinkBanner from '../LiveNowLink/LiveNowLinkBanner.vue'
import { useTopTournaments } from '../../composables'

const { topTournaments } = useTopTournaments()

const formattedTopTournaments = computed(() =>
  topTournaments.value.map((tournament) => ({
    publicUploadUrl: tournament.backgroundImage,
    url: tournament.link,
    title: tournament.name,
  })),
)

const props = withDefaults(
  defineProps<{
    isMini?: boolean
    isLiveVisible?: boolean
    size?: 's' | 'm'
  }>(),
  {
    size: 's',
  },
)

const { tournament: ratRaceWidget } = usePageTournament('bookmaker')

const isPreBannerVisible = computed(
  () => ratRaceWidget.value || props.isLiveVisible,
)

const live = useLiveEvents()
const liveCounter = computed(() => live.value.length)
</script>
